import { Permission } from '@api/types/permission';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import AppLogo from '@assets/svgs/app-logo.svg';
import { PageAlertDisplay } from '@components/alerts';
import BillingAlert from '@components/alerts/billing-alert';
import { CircleButton } from '@components/buttons';
import Column from '@components/layout-util-components/column';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import MenuItemProfile from '@components/menu-items/app/menu-item-profile';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import NotificationMenuItemWrapper from '@components/notifications/notification-menu-item-wrapper';
import NotificationsSidePanel from '@components/notifications/notifications-side-panel';
import {
  faBars,
  faCog,
  faHome,
  faLifeRing,
  faListCheck,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppTr } from '@i18n/use-app-tr';
import { Link, styled } from '@mui/material';
import { setNotificationsOpen } from '@store/notifications/notifications-slice';
import { selectIsNotificationsOpen } from '@store/notifications/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { usePermissions } from '@store/user/permission-hook';
import {
  selectIsCancelledCompanyStatus,
  selectIsLoggedInAndFullyAuthenticated,
} from '@store/user/user-selectors';
import { COMPANY_BILLING_PATH, useIsPath } from '@util/path-util';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#ECEDF0',
}));

const SideNav = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  width: isOpen ? 275 : 80,
  height: '100vh',
  padding: isOpen ? '14px 0 0px 0' : '14px 15px 0px 15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '0 0 auto',
  backgroundColor: 'white',
  transition: 'all 0.2s ease-in-out',
  '@media (max-width:768px)': {
    position: 'fixed',
    left: isOpen ? 0 : '-80px',
    zIndex: 100,
    paddingTop: 70,
    boxShadow: '0 0 10px rgba(0,0,0,0.25)',
  },

  '& > .nav-top': {
    width: '100%',
    height: '100px',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    flexShrink: 0,

    '@media (max-width:768px)': {
      position: 'fixed',
      borderBottom: '1px solid #D8DBE3',
      left: 0,
      top: 0,
      width: '100%',
      zIndex: 101,
      textAlign: 'center',
      background: '#fff',
      height: 80,
    },
  },

  '& .nav-logo': {
    transition: 'all 0.2s ease-in-out',
    width: 52,
    height: 52,
    position: 'relative',
    left: isOpen ? 34 : '50%',
    transform: isOpen ? null : 'translateX(-50%)',
    zIndex: 3,
    marginBottom: 10,
    '@media (max-width:768px)': {
      width: 52,
      height: 52,
    },
  },

  '& .menu-item-row': {
    height: 48,
    width: isOpen ? null : 48,
    borderRadius: 8,
    borderLeft: isOpen ? null : 0,
    paddingLeft: 14,
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 5,
    svg: {
      width: isOpen ? 31 : 'auto',
    },
  },

  '& .menu-item-descriptor': {
    display: 'block',
    overflow: 'hidden',
    opacity: isOpen ? '1' : '0',
    width: isOpen ? '100%' : '0px',
    transition: 'all 0.2s ease-in-out',
    '& p': {
      width: 155,
    },
  },

  '& .menu-item-profile': {
    padding: isOpen ? '15px 30px' : '15px 0',
    '& .name-container': {
      width: isOpen ? 'auto' : 0,
      display: isOpen ? 'block' : 'none',
    },
  },
}));

const ButtonWrapper = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  zIndex: 4,
  left: isOpen ? 60 : '50%',
  transform: 'translateX(-50%)',
  transition: 'all 0.2s ease-in-out, transform 0s',
  '@media (max-width:768px)': {
    right: 'auto',
    left: 30,
    top: 0,
    paddingTop: 22.5,
  },
}));

const NavItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',
  gap: 8,
});

const LowerNavItemsContainer = styled('div')({
  display: 'flex',
  padding: '25px 0',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 'auto',
  '>div:first-child': {
    marginBottom: 20,
    height: 1,
    background: 'rgba(0, 0, 0, 0.08)',
    border: 'none',
  },
  '>.menu-item': {
    padding: '12px 10px',
  },
});

const OutletContainer = styled('div')<{ isNavOpen: boolean }>(
  ({ isNavOpen }) => ({
    transition: 'all 0.2s ease-in-out',
    flex: '1 0 auto',
    marginLeft: isNavOpen ? 275 : 80,
    width: 'calc(100vw - 275px)',
    overflowY: 'auto',
    '@media (max-width:768px)': {
      marginLeft: 0,
      marginTop: 80,
    },
  })
);

export default function AppLayout() {
  const dispatch = useAppDispatch();
  const { t } = useAppTr('common');
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(() => {
    return localStorage?.getItem('openNav') === 'true';
  });
  const notificationButtonRef = useRef<HTMLDivElement>(null);

  const isCompanyBillingPath = useIsPath(COMPANY_BILLING_PATH);
  const isDashboard = useIsPath(['/', '/dashboard', '/home']);
  const isAnalysis = useIsPath('/analysis', { startsWith: true });
  const isGroups = useIsPath('/groups', { startsWith: true });
  const isCompanyProfile = useIsPath('/company-profile', { startsWith: true });
  const isTasks = useIsPath('/tasks', { startsWith: true });
  const isHelpCentre = useIsPath('/help-centre', { startsWith: true });

  const canSeeAnalysis = usePermissions([Permission.useCaseFunctionality]);

  const canSeeGroups = usePermissions([
    Permission.manageMyGroups,
    Permission.manageCompanyGroups,
  ]);

  const canSeeCompanyProfile = usePermissions([Permission.manageCompany]);

  const canSeeTasks = usePermissions([
    Permission.manageMyTasks,
    Permission.manageCompanyTasks,
  ]);

  const canManagePayments = usePermissions([Permission.manageCompanyPayments]);

  const isCompanyCancelled = useAppSelector(selectIsCancelledCompanyStatus);
  const isNotificationPanelOpen = useAppSelector(selectIsNotificationsOpen);
  const isFullyAuthenticated = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    localStorage?.setItem('openNav', openNav ? 'true' : 'false');
  }, [openNav]);

  const handleClickOutside = (event: MouseEvent) => {
    dispatch(setNotificationsOpen(false));
  };

  return (
    <>
      <PageContainer className={openNav ? 'nav-open' : 'nav-closed'}>
        <SideNav isOpen={openNav}>
          <div className="nav-top">
            <Link href="/">
              <img className="nav-logo" src={AppLogo} alt="logo" />
            </Link>
            <ButtonWrapper isOpen={openNav}>
              <CircleButton onClick={toggleNav}>
                <FontAwesomeIcon icon={faBars} />
              </CircleButton>
            </ButtonWrapper>
          </div>
          <Gap size={32} />
          <NavItemsContainer>
            <MenuItemRow
              onClick={() => navigate('/')}
              isSelected={isDashboard}
              icon={<FontAwesomeIcon icon={faHome} />}
            >
              {t('navBarItems.dashboard')}
            </MenuItemRow>
            {canSeeAnalysis && (
              <MenuItemRow
                onClick={() => navigate('/analysis')}
                isSelected={isAnalysis}
                icon={<RcaIndicatorIcon width={28} height="auto" />}
              >
                {t('navBarItems.analysis')}
              </MenuItemRow>
            )}
            {canSeeGroups && (
              <MenuItemRow
                onClick={() => navigate('/groups')}
                isSelected={isGroups}
                icon={<FontAwesomeIcon icon={faUsers} />}
              >
                Groups
              </MenuItemRow>
            )}
            {(canSeeCompanyProfile || canManagePayments) && (
              <MenuItemRow
                onClick={() =>
                  navigate(
                    canSeeCompanyProfile
                      ? '/company-profile'
                      : '/company-profile/billing'
                  )
                }
                isSelected={isCompanyProfile}
                icon={<FontAwesomeIcon icon={faCog} />}
              >
                {t('navBarItems.company')}
              </MenuItemRow>
            )}
            {canSeeTasks && (
              <MenuItemRow
                onClick={() => navigate('/tasks')}
                isSelected={isTasks}
                icon={<FontAwesomeIcon icon={faListCheck} />}
              >
                {t('navBarItems.tasks')}
              </MenuItemRow>
            )}
          </NavItemsContainer>
          <LowerNavItemsContainer>
            <Divider compact />
            {isFullyAuthenticated && (
              <>
                <NotificationMenuItemWrapper
                  buttonRef={notificationButtonRef}
                />
                <Gap size={8} />
              </>
            )}
            <MenuItemRow
              onClick={() => navigate('/help-centre')}
              icon={<FontAwesomeIcon icon={faLifeRing} />}
              isSelected={isHelpCentre}
            >
              {t('navBarItems.help')}
            </MenuItemRow>
          </LowerNavItemsContainer>

          <MenuItemProfile isSideDrawerOpen={openNav} />
        </SideNav>
        <OutletContainer isNavOpen={openNav}>
          <Column>
            {canManagePayments && <BillingAlert />}
            <PageAlertDisplay ignoreError absolute />
          </Column>
          {(!isCompanyCancelled || isCompanyBillingPath) && <Outlet />}
        </OutletContainer>
      </PageContainer>
      {isFullyAuthenticated && (
        <NotificationsSidePanel
          show={isNotificationPanelOpen}
          onClickOutside={handleClickOutside}
          toggleButtonRef={notificationButtonRef}
          onClose={() => {
            dispatch(setNotificationsOpen(false));
          }}
        />
      )}
    </>
  );
}
